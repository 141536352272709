.display-none {
    display: none;
}

.header-buuton-show .display-none{
  display: block !important;
}

 a.feedback {
    color: #212529;
    text-decoration: underline;
}

.file-upload{
    /* display: none; */
    position: absolute;
    width: 100%;
    left: 0;top: 0px;
    right: 0;
    height: 100%;
    opacity: 0;
    z-index: 99;
}

tr.soft-dange {
    background-color: #fad7dd;
}

tr.soft-dange .dropdown-ellipses{
  color:#12263F !important;
}

.rating-star{
    color: #b5c0cc;
}

img.video {
    width: 100%;
    border-radius: 5px;
}

.bg-transparent{
    background-color: transparent !important;
}

.dz-message {
    position: relative;
}

.transform-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.badge-soft-light {
    color: #12263F;
    background-color: #EDF2F9;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: #fff;
}

.flatpickr-months .flatpickr-month {
  background: #fff;
}

.flatpickr-weekdays {
    background:#fff;
}

span.flatpickr-weekday {
    background: #fff;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
 background-color: transparent;
}

a.display-content {
    display: contents;
    color: #12263F;
}

hr.navbar-divider {
    width: 100%;
}

.css-yk16xz-control {
  border-color: #D2DDEC !important; 
  height: calc(1.5em + 1rem + 2px); 
}

.css-1wa3eu0-placeholder{
    color: #12263F !important;
}

.form-control:not(.custom-select) {
    padding-right: 0.75rem;
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff;
}

option:first {
    color: #D2DDEC !important;
}

.alert-success {
    color: #FFFFFF;
    background-color: #42cc49;
    border-color: #42cc49;
}

.dz-default.dz-message img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropzone-single.dz-max-files-reached .dz-message {
    background-color: rgba(18,38,63,.9);
    color: #fff;
    opacity: 0;
}

select:invalid{
    color: gray;
}

.navbar-nav li.nav-item a {
    text-transform: capitalize;
}

.line-height {
    line-height: 1.4;
}

.spinner-border-sm {
    position: absolute;
    top: 17px;
    left: 15px;
}

.spinner-border {
    border: 0.25em solid #fff;
    border-right-color: transparent;
}

.w-20{
    width: 20%;
}

.w-10{
    width: 10%;
}

.navbar.z-index {
    z-index: 999;
}
.modal-backdrop{
    z-index:99;
}

.mob-mb-30{
    margin-bottom: 30px !important;
}

.sign-alert {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
}

table.table.card-table a {
    color: #12263F;
}

span.select-category {
    position: absolute;
    bottom: 33px;
    right: 63px;
    font-size: 12px;
}

.css-1wa3eu0-placeholder {
    color: #B1C2D9 !important;
}

.mobile-show{
    display: none;
}

.btn-gry {
    background: #e6e6e6;
}


@media screen and (max-width: 767px) {
    .card.mt-5.px-4.pt-4.mob-none {
        display: none;
    }

    .mobile-show{
        display:block !important
    }

  .brnad-logo {
        margin: 0 !important;
    }

    .brnad-logo img {
        margin-bottom:0rem !important;
    }
   
}

@media screen and (max-width:1199px){
    .mob-mb-4{
        margin-bottom:20px !important;
    }
}


.d-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer; 
    
  }
  
  .d-tooltip .d-tooltiptext {
    display: none;
    width: 120px;
    background-color: #6e84a3;
    color: #fff;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 99999;
    top: 120%;
    left: 10%;
    text-align: center;
    margin-left:0%;
    padding: 10px;
}

.d-tooltip .d-tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent#6e84a3 transparent;
}
  
  .d-tooltip:hover .d-tooltiptext {
    display:block;
  }

  tr.bg-light td {
    font-weight: 700;
}

.brand-border-top td {
    border-top: 1px solid #e7e7e7;
}

.brand-border-top tr:last-child .d-tooltip .d-tooltiptext {
    top: -200%;
}

.brand-border-top tr:last-child .d-tooltip .d-tooltiptext::after{
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:#6e84a3 transparent transparent transparent;
}

.opacity-8 {
    opacity: .8;
}

.font-sm {
    font-size: .8rem;
}

.top-4 {
    top: 4px;
}

.brnad-logo {
    margin:auto; max-width: 70px;
}

.brnad-logo img {
    width: 100%; object-fit: fill; height: 60px;
    margin-bottom: 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.md_w {
 width:40%
}